export enum LeagueTypes {
  'BASKETBALL' = 'LT_BASKETBALL',
  'BASKETBALLCHEER' = 'LT_BASKETBALL_BASKETBALLCHEERLEADING',
  'SOCCER' = 'LT_SOCCER',
  'FOOTBALL' = 'LT_FOOTBALL',
  'BASKETBALLCAMP' = 'LT_BASKETBALLCAMP',
  'CHEERLEADINGCAMP' = 'LT_CHEERLEADINGCAMP',
  'SOCCERCAMP' = 'LT_SOCCERCAMP',
  'FOOTBALLCHEER' = 'LT_FOOTBALL_FOOTBALLCHEERLEADING',
  'FOOTBALLCAMP' = 'LT_FOOTBALLCAMP',
  'VOLLEYBALL' = 'LT_VOLLEYBALL',
  'BASEBALL' = 'LT_BASEBALL',
  'UPWARDSELECT' = 'LT_UPWARDSELECTMSS',
}
