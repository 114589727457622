
import { Vue, Component } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import { RoleUnion } from '@/common/Authorization/RoleUnion'
import { LeagueInfoCondensed } from '@/models/Program/LeagueInfoCondensed'
import * as programStore from '@/store/programs'

import * as authorization from '@/store/authorization'
import { AccountRoles } from '@/common/Authorization/AccountRoles'
import { VolunteerRoles } from '@/common/Authorization/VolunteerRoles'

import {
  getEmptyGettingStartedResource,
  GettingStartedResource,
} from '@/models/Resources/GettingStartedResource'
import contentManagementClient from '@/clients/contentManagementClient'
import { LeagueTypes } from '@/common/LeagueTypes'
import store from '@/store'

@Component({
  components: {},
})
export default class GettingStarted extends Vue {
  @Getter(authorization.getterNames.currentRole, { namespace: authorization.namespace })
  private readonly currentRole!: RoleUnion

  @Getter(programStore.getterNames.currentProgram, { namespace: programStore.namespace })
  private readonly currentProgram!: LeagueInfoCondensed

  private gsResource = getEmptyGettingStartedResource()

  private async created() {
    this.gsResource =
      (await contentManagementClient.getGettingStartedResource(
        this.currentProgram.upwardLeagueID ?? '',
        this.currentProgram.typeProgramID ?? '',
        this.currentRole.roleId
      )) ?? getEmptyGettingStartedResource()
    this.gsResource = this.applyContentOverrides(this.gsResource, this.$router)
  }

  private FIRST_PRESBYTERIAN_NASHVILLE_OVERRIDES = [
    {
      accountNumber: '31938',
      leagueType: LeagueTypes.SOCCER,
      roles: [VolunteerRoles.COACH],
      replacements: [
        {
          find: 'https://uuresources.blob.core.windows.net/media/Volunteers/24-25/SOL/24-25_SOL_Coach_Playbook_VIRTUES.pdf',
          replace:
            'https://uuresources.blob.core.windows.net/media/Volunteers/24-25/MSL/FPCNashvilleCoachManualSpring2025.pdf',
        },
        {
          find: 'PDF: Coach Playbook',
          replace: 'PDF: Coach Manual',
        },
      ],
    },
  ]

  /**
   * Applies content overrides based on account, league type, and role
   * @param resource The resource to potentially modify
   * @param router Vue router instance with $allowIfAny method
   * @returns A new resource with overrides applied if conditions match
   */
  private applyContentOverrides(resource: GettingStartedResource, router: any): GettingStartedResource {
    // Create a deep copy to avoid mutating the original
    const result = JSON.parse(JSON.stringify(resource))

    // Get current user context
    const accountNumber = store.getters.authentication.firstAccountNumber
    const currentProgram = store.getters.programs.currentProgram!
    const leagueType = currentProgram?.typeLeagueID

    // Find matching override configuration
    const matchingOverride = this.FIRST_PRESBYTERIAN_NASHVILLE_OVERRIDES.find(
      (override) =>
        accountNumber === override.accountNumber &&
        leagueType === override.leagueType &&
        router.app.$allowIfAny(override.roles)
    )

    // Apply replacements if there's a match and content exists
    if (matchingOverride && result.documentContent) {
      let modifiedContent = result.documentContent
      for (const { find, replace } of matchingOverride.replacements) {
        modifiedContent = modifiedContent.replace(find, replace)
      }
      return {
        ...result,
        documentContent: modifiedContent,
      }
    }

    return result
  }

  //leaving everything from here down for reference to what was done before in case teh new CMS mappings are incorrect.

  documentIdMap = new Map([
    [
      AccountRoles.DIRECTOR.toString(),
      new Map([
        ['BASKETBALL', 'gettingStartedProgramDirectorBasketball'],
        ['BASKETBALLCAMP', 'gettingStartedProgramDirectorCampBasketball'],
        ['FOOTBALL', 'gettingStartedProgramDirectorFootball'],
        ['FOOTBALLCAMP', 'gettingStartedProgramDirectorCampFootball'],
        ['SOCCER', 'gettingStartedProgramDirectorSoccer'],
        ['SOCCERCAMP', 'gettingStartedProgramDirectorCampSoccer'],
        ['VOLLEYBALL', 'gettingStartedProgramDirectorVolleyball'],
        ['VOLLEYBALLCAMP', 'gettingStartedProgramDirectorCampVolleyball'],
        ['CHEERCAMP', 'gettingStartedProgramDirectorCampCheerleading'],
      ]),
    ],
    [
      VolunteerRoles.ADVERTISING_COMMISSIONER.toString(),
      new Map([
        ['BASKETBALL', 'gettingStartedAdvertisingCommissionerBasketball'],
        ['BASKETBALLCAMP', 'gettingStartedAdvertisingCommissionerBasketball'],
        ['FOOTBALL', 'gettingStartedAdvertisingCommissionerFootball'],
        ['FOOTBALLCAMP', 'gettingStartedAdvertisingCommissionerFootball'],
        ['SOCCER', 'gettingStartedAdvertisingCommissionerSoccer'],
        ['SOCCERCAMP', 'gettingStartedAdvertisingCommissionerSoccer'],
        ['VOLLEYBALL', 'gettingStartedAdvertisingCommissionerVolleyball'],
        ['VOLLEYBALLCAMP', 'gettingStartedAdvertisingCommissionerVolleyball'],
      ]),
    ],
    [
      VolunteerRoles.REFEREE_COMMISSIONER.toString(),
      new Map([
        ['BASKETBALL', 'gettingStartedRefereeCommissionerBasketball'],
        ['BASKETBALLCAMP', 'gettingStartedRefereeCommissionerBasketball'],
        ['FOOTBALL', 'gettingStartedRefereeCommissionerFootball'],
        ['FOOTBALLCAMP', 'gettingStartedRefereeCommissionerFootball'],
        ['SOCCER', 'gettingStartedRefereeCommissionerSoccer'],
        ['SOCCERCAMP', 'gettingStartedRefereeCommissionerSoccer'],
        ['VOLLEYBALL', 'gettingStartedRefereeCommissionerVolleyball'],
        ['VOLLEYBALLCAMP', 'gettingStartedRefereeCommissionerVolleyball'],
      ]),
    ],
    [
      VolunteerRoles.COACH.toString(),
      new Map([
        ['BASKETBALL', 'gettingStartedCoachBasketball'],
        ['BASKETBALLCAMP', 'gettingStartedCoachCampBasketball'],
        ['FOOTBALL', 'gettingStartedCoachFootball'],
        ['FOOTBALLCAMP', 'gettingStartedCoachCampFootball'],
        ['SOCCER', 'gettingStartedCoachSoccer'],
        ['SOCCERCAMP', 'gettingStartedCoachCampSoccer'],
        ['VOLLEYBALL', 'gettingStartedCoachVolleyball'],
        ['VOLLEYBALLCAMP', 'gettingStartedCoachCampVolleyball'],
        ['CHEERCAMP', 'gettingStartedCoachCampCheerleading'],
      ]),
    ],
    [
      VolunteerRoles.COACH_COMMISSIONER.toString(),
      new Map([
        ['BASKETBALL', 'gettingStartedCoachCommissionerBasketball'],
        ['BASKETBALLCAMP', 'gettingStartedCoachCommissionerBasketball'],
        ['FOOTBALL', 'gettingStartedCoachCommissionerFootball'],
        ['SOCCER', 'gettingStartedCoachCommissionerSoccer'],
        ['VOLLEYBALL', 'gettingStartedCoachCommissionerVolleyball'],
      ]),
    ],
    [
      VolunteerRoles.REFEREE.toString(),
      new Map([
        ['BASKETBALL', 'gettingStartedRefereeBasketball'],
        ['BASKETBALLCAMP', 'gettingStartedRefereeCampBasketball'],
        ['FOOTBALL', 'gettingStartedRefereeFootball'],
        ['FOOTBALLCAMP', 'gettingStartedRefereeCampFootball'],
        ['SOCCER', 'gettingStartedRefereeSoccer'],
        ['SOCCERCAMP', 'gettingStartedRefereeCampSoccer'],
        ['VOLLEYBALL', 'gettingStartedRefereeVolleyball'],
        ['VOLLEYBALLCAMP', 'gettingStartedRefereeCampVolleyball'],
        ['CHEERCAMP', 'gettingStartedRefereeCampCheerleading'],
      ]),
    ],
    [VolunteerRoles.PRAYER_COMMISSIONER.toString(), new Map([['', 'gettingStartedPrayerCommissioner']])],
    [VolunteerRoles.HOST_COMMISSIONER.toString(), new Map([['', 'gettingStartedHostCommissioner']])],
    [VolunteerRoles.MINISTRY_COORDINATOR.toString(), new Map([['', 'gettingStartedMinistryCoordinator']])],
    [VolunteerRoles.HALFTIME_COMMISSIONER.toString(), new Map([['', 'gettingStartedGamedayCommissioner']])],
    [VolunteerRoles.CHEER_DIRECTOR.toString(), new Map([['', 'gettingStartedCheerDirector']])],
  ])

  sportMap = new Map([
    ['BASKETBALL', 'BASKETBALL'],
    ['BASKETBALLCAMP', 'BASKETBALLCAMP'],
    ['BASKETBALLCLINIC', 'BASKETBALL'],
    ['BASKETBALLCHEERLEADING', 'BASKETBALL'],
    ['SOCCER', 'SOCCER'],
    ['VOLLEYBALL', 'VOLLEYBALL'],
    ['VOLLEYBALLCAMP', 'VOLLEYBALLCAMP'],
    ['SOCCERCAMP', 'SOCCERCAMP'],
    ['SOCCERCLINIC', 'SOCCER'],
    ['FOOTBALL', 'FOOTBALL'],
    ['FOOTBALLCAMP', 'FOOTBALLCAMP'],
    ['FOOTBALLCLINIC', 'FOOTBALL'],
    ['FOOTBALLCHEERLEADING', 'FOOTBALL'],
    ['CHEERCAMP', 'CHEERCAMP'],
  ])

  private get documentId() {
    const nonSportDocumentId = this.documentIdMap.get(this.currentRole.roleId)?.get('')
    if (nonSportDocumentId) {
      return nonSportDocumentId
    }
    const sport = this.sportMap.get(this.currentProgram.typeProgramID ?? '') ?? ''
    const sportDocumentId = this.documentIdMap.get(this.currentRole.roleId)?.get(sport)
    return sportDocumentId
  }
}
